<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <!-- <vs-th sort-key width="5%">No</vs-th> -->
        <vs-th sort-key="code">Customer</vs-th>
        <vs-th sort-key="address">Address</vs-th>
        <vs-th sort-key="contact_name">Contact</vs-th>
        <vs-th sort-key="is_pkp">Tax & Invoice</vs-th>
        <vs-th sort-key="virtual_account">Virtual Account</vs-th>
        <vs-th>CreatedOn</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="whitespace-no-wrap">
            <feather-icon
              title="Address List"
              icon="ListIcon"
              svgClasses="w-5 h-5 hover:text-success stroke-current"
              @click.stop="handleCustomerAddress(data[indextr].id)"
            />
            <feather-icon
              title="Edit"
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="
                handleEdit(
                  data[indextr].id,
                  tr.status_approval,
                  tr.territory_code + ' - ' + tr.territory_name
                )
              "
            />
            <!-- <feather-icon
              title="Delete"
              icon="TrashIcon"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              class="ml-2"
              @click.stop="handleDelete(data[indextr].id)"
            /> -->
          </vs-td>
          <!-- <vs-td :data="data[indextr].code">{{((length*page)-length )+ indextr+1}}</vs-td> -->
          <vs-td :data="data[indextr].code">
            <span v-if="tr.status_approval == '2'"><span v-if="tr.code_external == ''">Code : {{ tr.code }}</span><span v-else>Code : {{ tr.code_external }}</span></span>
            <span v-else>Code NOO : {{ tr.code_noo }}</span>
            <br />
            Outlet Name : {{ data[indextr].name }}
            <br />
            Channel : {{ data[indextr].customer_category_name }}
            <br />
            Delivery Type : {{ data[indextr].delivery_type_name }}
            <br />
            Group 1 : {{ data[indextr].customer_group_name1 }}
            <br />
            Group 2 : {{ data[indextr].customer_group_name2 }}
            <br />
            Group 3 : {{ data[indextr].customer_group_name3 }}
            <br />
            Pricing Group : {{ data[indextr].pricing_group_code_name }}
            <br />
            Payment Type : {{ data[indextr].payment_type }}
            <br />
            Payment Term : {{ data[indextr].payment_term_name }} ({{
              data[indextr].payment_term_day
            }}
            day)
            <br />
            <!-- Territory : {{ data[indextr].territory_agg }}
            <br /> -->
            Invoice Model : {{ data[indextr].invoice_model }}
            <br />
            Credit Limit :
            {{
              data[indextr].customer_limit_amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }}
            <br />
            Business Partner : {{ data[indextr].bussiness_partner }}
            <br />
            Status : {{ data[indextr].status }}
            <br />
            Status Approval : {{ data[indextr].string_status_approval }}
            <br />
            Reason : {{ data[indextr].remark_approval }}
            <br />
            Priority : {{ data[indextr].priority_type }}<br />
            Territory : {{ data[indextr].territory_code }} -
            {{ data[indextr].territory_name }}
            <!-- <br />
            Zone : {{ data[indextr].customer_zone_name }} -->
          </vs-td>
          <vs-td :data="data[indextr].address">
            Country : {{ data[indextr].country }}
            <br />
            Province :
            {{ data[indextr].province }} <br />
            City :
            {{ data[indextr].city }} <br />
            District :
            {{ data[indextr].district }} <br />
            Sub-District :
            {{ data[indextr].sub_district }} <br />
            Address :
            {{ data[indextr].address }} <br />
            Postal Code :
            {{ data[indextr].postal_code }} <br />
            Transport Zone :
            {{ data[indextr].transport_zone }} <br />
            Rt / Rw :
            {{ data[indextr].rt_rw }} <br />
            Street No :
            {{ data[indextr].street_no }} <br />
            Latitude :
            {{ data[indextr].latitude }} <br />
            Longitude :
            {{ data[indextr].longitude }}
          </vs-td>
          <vs-td :data="data[indextr].contact_name">
            PIC Name : {{ data[indextr].nik_name }}
            <br />
            Phone : {{ data[indextr].phone }}
            <br />
            Mobile : {{ data[indextr].mobile }}
            <br />
            Email : {{ data[indextr].email }}
            <br />
            Fax : {{ data[indextr].fax }}
          </vs-td>
          <vs-td>
            <div>
              <!-- NPWP : {{ data[indextr].npwp }}
              <br /> -->
              <!-- TDP : {{ data[indextr].tdp }}
              <br /> -->
              <!-- NPWP Name : {{ data[indextr].npwp_name }}
              <br />
              SPPKP : {{ data[indextr].sppkp }}
              <br />
              Tax Address : {{ data[indextr].tax_address }}
              <br />
              Domisili : {{ data[indextr].domisili }} -->

              Max Open Bill : {{ data[indextr].max_open_bill === 0 ? 'Unlimited' : data[indextr].max_open_bill }}
              <br />
              Invoice Model : {{ data[indextr].invoice_model }}
              <br />
              Tax Collector : {{ data[indextr].is_tax_collector == '1' ? 'YES' : 'NO' }}
              <br />
              Tax Exempt : {{ data[indextr].is_tax_exempt == '1' ? 'YES' : 'NO' }}
              <br />
              PKP : {{ data[indextr].is_pkp == '1' ? 'YES' : 'NO' }}
              <br />
              NPWP : {{ data[indextr].npwp }}
              <br />
              NPWP Name : {{ data[indextr].npwp_name }}
              <br />
              SPPKP : {{ data[indextr].sppkp }}
              <br />
              Tax Address : {{ data[indextr].tax_address }}
              <br />
              Domisili : {{ data[indextr].domisili }}
            </div>
          </vs-td>
          <vs-td>
            <div :key="idx" v-for="(dt, idx) in tr.customer_account">
              Bank Name : {{ dt.bank_name }}
              <br />
              Account Number : {{ dt.account_number }}
              <br />
              <hr>
            </div>
          </vs-td>
          <vs-td :data="tr.created_at">
            Created On : {{ tr.created_at }} <br />
            Created By : {{ tr.created_by }} <br />
            Updated On : {{ tr.updated_at }} <br />
            Updated By : {{ tr.updated_by }} <br />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    customerCategoryId: {
      type: Number,
    },
    territorryId: {
      type: Number,
    },
    detail: {
      type: Boolean,
    },
    statusApproval: {
      type: String,
    },
  },
  data() {
    return {
      baseUrl: "/api/oms/v1/sfa-master/customer",
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    // handleClose() {
    //   this.getData();
    //   this.$emit("close");
    // },
    // handleCreate() {
    //   this.customerId = null;
    //   this.detail = true;
    // },
    getData() {
      this.$emit("close");
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            customer_category_id: this.customerCategoryId,
            territory_id: this.territorryId,
            type: "End Customer",
            status_approval: this.statusApproval,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this customer data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The customer data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id, status_approval, territory) {
      this.$emit("edit", id, status_approval, territory);
    },
    // handleSendForApproval(id){
    //   this.$vs.dialog({
    //     type: "confirm",
    //     color: "primary",
    //     title: `Confirm`,
    //     text: "Are you sure?",
    //     accept: () => {
    //       this.$vs.loading();
    //       this.$http
    //         .post(this.baseUrl + "-approval/" + id)
    //         .then((resp) => {
    //           if (resp.code == 200) {
    //             this.$vs.notify({
    //               color: "success",
    //               title: "Success",
    //               text: "Success",
    //               position: "top-right",
    //               iconPack: "feather",
    //               icon: "icon-x-circle",
    //             });
    //           } else {
    //             this.$vs.notify({
    //               color: "danger",
    //               title: "Error",
    //               text: resp.message,
    //               position: "top-right",
    //               iconPack: "feather",
    //               icon: "icon-x-circle",
    //             });
    //           }

    //           this.$vs.loading.close();
    //           this.getData();
    //         })
    //         .catch((error) => {
    //           this.$vs.loading.close();
    //           console.log(error);
    //         });
    //     },
    //   });
    // },
    handleCustomerAddress(id) {
      this.$router.push("/approval/customer-address/" + id);
    },
  },
  watch: {
    customerCategoryId() {
      this.getData();
    },
    territorryId() {
      this.getData();
    },
    detail() {
      if (this.detail == false) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
